<template>
  <div class="liveDetail">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">直播信息</span>
      </div>
      <div class="news">
        <div class="newsL">
          <div class="newsLInfo">
            <div>
              <span class="newsTitle">直播名称：</span>
              <span>{{ detail.liveName }}</span>
            </div>
            <div>
              <span class="newsTitle">直播时间：</span>
              <span>{{ detail.startTimeShow }}</span>
            </div>
          </div>
          <div class="newsLInfo">
            <div>
              <span class="newsTitle">直播方式：</span>
              <span>{{ detail.liveTypeShow }}</span>
            </div>
            <div>
              <span class="newsTitle">讲师名称：</span>
              <span>{{ detail.teacherName }}</span>
            </div>
          </div>
        </div>
        <div class="newsR">
          <div class="newsRInfo">
            <div class="text">直播介绍：</div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="introduce" v-html="detail.liveIntroduce"></div>
          </div>
          <!-- <div class="newsRInfo">
            <div class="text">讲师介绍：</div>
            <div class="introduce" v-html="detail.teacherIntroduce"></div>
          </div> -->
        </div>
      </div>
    </el-card>
    <el-card class="card">
      <div slot="header" class="clearfix">
        <span class="title">观看人员</span>
        <span class="lookPeople">观看人数：{{ list.length }}人</span>
      </div>
      <table-list
        :loading="loading"
        :data="list"
        :columns="columns(this)"
        :pager="pager"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
    </el-card>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    label: '学员姓名',
    minWidth: 150,
  },
  {
    prop: 'sex',
    label: '性别',
    formatter: row => {
      if (row.sex == 0) {
        return '未知'
      } else if (row.sex == 1) {
        return '男'
      } else {
        return '女'
      }
    },
    minWidth: 100,
  },
  {
    prop: 'phone',
    label: '手机号码',
    minWidth: 150,
  },
  {
    prop: 'firstWatchTime',
    label: '首次观看直播时间',
    minWidth: 250,
  },
  {
    prop: 'watchDuration',
    label: '观看分钟',
    minWidth: 150,
  },
]
import { editSearch, liveRecord } from '@/api/live'
import to from 'await-to'
import TableList from '@/components/TableList'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      columns,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      detail: {},
      selectList: [],
    }
  },
  created() {
    this.editSearchData()
    this.liveRecord()
  },
  methods: {
    async liveRecord() {
      const { size, current } = this.pager
      const [res, err] = await to(liveRecord({ size, current, liveId: this.$route.query.liveId }))
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async editSearchData() {
      const [res, err] = await to(editSearch({ liveId: this.$route.query.liveId }))
      if (err) return this.$message.warning(err.msg)
      this.detail = res.data
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.liveRecord()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.liveRecord()
    },
  },
}
</script>
<style scoped lang="scss">
.box-card {
  margin-bottom: 20px !important;
  .news {
    overflow: hidden;
    .newsL {
      display: flex;
      font-size: 14px;
      .newsLInfo {
        &:last-child {
          margin-left: 174px;
        }
        div {
          line-height: 40px;
          .newsTitle {
            color: #909399;
          }
        }
      }
    }
    .newsR {
      font-size: 14px;
      margin-top: 15px;
      .newsRInfo {
        display: flex;
        margin-bottom: 28px;
        .text {
          color: #909399;
        }
        .introduce {
          line-height: 20px;
        }
      }
    }
  }
}
.lookPeople {
  font-size: 18px;
  color: #ff7b33;
  float: right;
  padding: 3px 0;
}
</style>
